import React from 'react';
import { Skeleton, Stack, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  contestCardSection: {
    background: theme.colors.gray[2],
    padding: theme.spacing.md,
    width: `calc(100% + ${2 * theme.spacing.lg}px)`,
    margin: `${theme.spacing.md}px -${theme.spacing.lg}px`,
    display: 'flex',
    justifyContent: 'center',

    [theme.fn.smallerThan('md')]: {
      padding: theme.spacing.xs,
    },
  },
  contestCardContainer: {
    maxWidth: 440,
    flexGrow: 1,
  },
  loaderContainer: {
    height: '100%',
    minHeight: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing.md,
      textAlign: 'center',
    },
  },
}));

function EnterContestLoadingScreen() {
  const { classes } = useStyles();

  return (
    <Stack align="center">
      <Skeleton height={30} width={200} />
      <Skeleton height={20} maw={400} />
      <Skeleton height={20} width={100} />

      <div className={classes.contestCardSection}>
        <div className={classes.contestCardContainer}>
          <Skeleton height={160} width="100%" />
        </div>
      </div>

      <Skeleton height={40} width={200} />

      <div className={classes.footer}>
        <Skeleton height={48} width="100%" />
      </div>
    </Stack>
  );
}

export default EnterContestLoadingScreen;
