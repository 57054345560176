import { createStyles } from '@mantine/core';

import { isDarkMode } from '~/config/themeUtils';

const useStyles = createStyles(
  (
    theme,
    {
      isStatic,
      actionBannerColor = 'orange',
    }: { isStatic?: boolean; actionBannerColor?: string } = {}
  ) => ({
    container: {
      padding: theme.spacing.md,
      transition: 'transform ease-in-out 200ms',

      ...(isDarkMode(theme) ? {} : { border: `1px solid ${theme.colors.gray[2]}` }),

      ...(!isStatic
        ? { cursor: 'pointer', '&:hover': { transform: 'scale(1.02)' } }
        : {}),
    },
    top: {
      flexWrap: 'nowrap',
    },
    topLeftSlot: {
      overflow: 'hidden',
    },
    titleWrapper: {
      overflow: 'hidden',
    },
    infoItemLabel: {
      fontSize: theme.fontSizes.xs,
      color: theme.colors.gray[6],
    },
    infoItemValue: {
      fontWeight: 700,
      fontSize: theme.fontSizes.sm,
    },
    menu: {
      position: 'absolute',
      right: 4,
      top: 6,
    },
    actionBanner: {
      background: theme.colors.red[1],
      color: theme.colors.red[9],
      padding: 4,
      fontWeight: 600,
      fontSize: theme.fontSizes.xs,
      textAlign: 'center',
      margin: `${theme.spacing.sm}px -${theme.spacing.md}px -${theme.spacing.md}px -${theme.spacing.md}px`,

      ...(() => {
        switch (actionBannerColor) {
          case 'orange':
            return {
              background: theme.colors.orange[1],
              color: theme.colors.orange[9],
            };
          case 'gray':
          default:
            return {
              background: theme.colors.gray[3],
              color: theme.colors.gray[9],
            };
        }
      })(),
    },
  })
);

export default useStyles;
