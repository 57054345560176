import { createStyles } from "@mantine/core";
import { forwardRef } from "react";

const useStyles = createStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: theme.other.zIndex.dialogModal,
    overflow: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    backdropFilter: 'blur(2px)',
  },
}));

const DialogBackdrop = forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode,
    onClick?: () => void
    className?: string;
  }
>(
  ({ children, onClick, className }, ref) => {
    const { classes, cx } = useStyles();

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.target !== event.currentTarget) { return; }
      event.stopPropagation();
      onClick?.();
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        ref={ref}
        className={cx(classes.container, className)}
        onClick={handleClick}
      >
        {children}
      </div>
    );
  }
);

DialogBackdrop.displayName = 'DialogBackdrop';

export default DialogBackdrop;
