import axios from 'axios';

import RequestHeadersBase from '~/common/constants/RequestHeadersBase';

import { handleRefreshInterceptor } from '../interceptors/handle-refresh.interceptor';
import { setAuthTokenInterceptor } from '../interceptors/set-auth-token.interceptor';
import { setAuthEndpoint } from '../interceptors/set-auth-endpoint.interceptor';

export const usersInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL_IDENTITY_SERVICE,
  headers: {
    ...RequestHeadersBase,
  },
});

setAuthEndpoint(usersInstance);
setAuthTokenInterceptor(usersInstance);
handleRefreshInterceptor(usersInstance);

export const paymentUsersInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL_PAYMENTS_SERVICE}`,
  headers: {
    ...RequestHeadersBase,
  },
});

setAuthTokenInterceptor(paymentUsersInstance);
handleRefreshInterceptor(paymentUsersInstance);
