import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    padding: theme.other.spacing._24,

    [theme.fn.smallerThan('sm')]: {
      padding: theme.other.spacing._16,
    },
  },
}));

function DialogBody({ children, className, ...props }: React.HTMLProps<HTMLDivElement>) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)} {...props}>
      {children}
    </div>
  )
}

export default DialogBody;
