import React from 'react';

import useEnterContestModal from './useEnterContestModal';
import EnterContestModal from './EnterContestModal';

type AddEntriesModalProps = {
  contestId: string;
  isOpen: boolean;
  isCloseDisabled?: boolean;
  onClose: () => void;
  onSuccess?: (entryIDs: string[]) => void;
};

function ConnectedEnterContestModal({
  isOpen,
  contestId,
  isCloseDisabled,
  onClose,
  onSuccess
}: AddEntriesModalProps) {
  const {
    availableBalance,
    contest,
    createEntriesErrorMessage,
    handleCreateEntries,
    handleOpenDepositModal,
    isCreateEntriesReady,
    nodeRef,
    state,
  } = useEnterContestModal({
    contestId,
    onClose,
    onSuccess,
  });

  return (
    <EnterContestModal
      availableBalance={availableBalance}
      contest={contest.data}
      createEntriesErrorMessage={createEntriesErrorMessage}
      handleCreateEntries={handleCreateEntries}
      handleOpenDepositModal={handleOpenDepositModal}
      isCloseDisabled={isCloseDisabled}
      isCreateEntriesReady={isCreateEntriesReady}
      isOpen={isOpen}
      isRestartedContest={contest.data?.settings.contestRestart?.isRestarted}
      nodeRef={nodeRef}
      onClose={onClose}
      state={state}
    />
  );
}

export default ConnectedEnterContestModal;
