import type { PropsWithChildren } from 'react';
import React from 'react';
import { IconBarrierBlock } from '@tabler/icons-react';
import { AppShell, MantineProvider, useMantineTheme } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import Cookies from 'js-cookie';

import { FEATURE } from '~/common/enums/feature.enum';
import { Footer } from '~/components/shared/Footer/Footer';
import { Header } from '~/domains/common/components/Header/Header';
import { useTreatments } from '~/domains/common/hooks/useTreatments';
import { SplashLoader } from '~/domains/common/components/SplashLoader/SplashLoader';

import { FullScreenMessage } from '../../../common/components/FullScreenMessage/FullScreenMessage';
import { SplashLogo } from '../../../../components/shared/Logos/SplashLogo';

export function MaintenanceWrapper({ children }: PropsWithChildren) {
  const { isReady, treatments } = useTreatments([
    FEATURE.SHOW_MAINTENANCE_SCREEN,
    FEATURE.ENABLE_UNIFIED_LOGIN,
  ]);
  const { t } = useTranslation('app');

  const theme = useMantineTheme();

  if (!isReady) {
    return (
      <MantineProvider inherit theme={{ colorScheme: 'light' }}>
        <SplashLoader />;
      </MantineProvider>
    );
  }

  const isMaintenanceEnabled = treatments[FEATURE.SHOW_MAINTENANCE_SCREEN].treatment === 'on';
  const maintenanceConfig = treatments[FEATURE.SHOW_MAINTENANCE_SCREEN].config;

  // Temporary cookie for Universal ID - 2/6/2025
  if (process.env.NEXT_PUBLIC_ENV !== 'production') {
    Cookies.set('ENABLE_UNIFIED_LOGIN', treatments[FEATURE.ENABLE_UNIFIED_LOGIN].treatment);
  }

  if (!isMaintenanceEnabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <>
      <AppShell
        padding={0}
        header={
          <Header>
            <SplashLogo />
          </Header>
        }
        styles={{ main: { padding: 0, minHeight: '95vh' } }}
      >
        <MantineProvider inherit theme={{ colorScheme: 'light' }}>
          <FullScreenMessage
            icon={<IconBarrierBlock color={theme.colors.red[5]} />}
            title={maintenanceConfig?.title ?? t('maintenanceWrapper.title')}
            subtitle={maintenanceConfig?.message ?? t('maintenanceWrapper.subtitle')}
          />
        </MantineProvider>
      </AppShell>
      <Footer isRestricted />
    </>
  );
}
