import { createStyles } from "@mantine/core";
import { CSSTransition } from "react-transition-group";
import { useRef } from "react";

import { UnstyledButton } from "~/domains/design-system/Button";
import IconX from "~/domains/design-system/icons/IconX";

import type { DialogComponentProps } from "../types";
import { TIMEOUT } from "../constants";

const useStyles = createStyles((theme, {
  hasWrapperContent,
  fullScreen,
  isClosing,
  isOpen,
}: {
  hasWrapperContent: boolean;
  fullScreen?: boolean;
  isClosing: boolean;
  isOpen: boolean;
}) => ({
  modal: {
    position: 'relative',
    backgroundColor: hasWrapperContent ? theme.colors.gray[0] : theme.white,
    borderRadius: theme.other.spacing._24,
    alignSelf: 'center',
    justifySelf: 'center',
    display: isOpen || isClosing ? 'flex' : 'none',
    flexDirection: 'column',

    ...(hasWrapperContent ? {
      [theme.fn.largerThan('sm')]: {
        padding: theme.other.spacing._12,
        borderRadius: theme.other.spacing._32,
      },
      paddingTop: 0,
      backgroundColor: theme.colors.gray[0],
    } : {
      backgroundColor: theme.white,
    }),

    ...(fullScreen ? {
      width: '100%',
      minHeight: '100svh',
      height: 'max-content',
      alignSelf: 'stretch',
      borderRadius: 0,
    } : {}),

    ...(fullScreen ? {
      '&.enter': {
        opacity: 0,
      },
      '&.enter-active': {
        transition: `opacity ${TIMEOUT}ms ease-in-out`,
        opacity: 1,
      },
      '&.enter-done': {
        opacity: 1,
      },
      '&.exit': {
        opacity: 1,
      },
      '&.exit-active': {
        opacity: 0,
        transition: `opacity ${TIMEOUT}ms ease-in-out`,
      },
      '&.exit-done': {
        opacity: 0,
      },
    } : {
      marginTop: theme.other.spacing._32,
      marginBottom: theme.other.spacing._32,

      '&.enter': {
        transform: `translateY(-${theme.other.spacing._32}px)`,
        opacity: 0,
      },
      '&.enter-active': {
        transition: `transform ${TIMEOUT}ms ease-in-out, opacity ${TIMEOUT}ms ease-in-out`,
        transform: `translateY(0)`,
        opacity: 1,
      },
      '&.enter-done': {
        transform: `translateY(0)`,
        opacity: 1,
      },
      '&.exit': {
        transform: `translateY(0)`,
        opacity: 1,
      },
      '&.exit-active': {
        transform: `translateY(${theme.other.spacing._32}px)`,
        opacity: 0,
        transition: `transform ${TIMEOUT}ms ease-in-out, opacity ${TIMEOUT}ms ease-in-out`,
      },
      '&.exit-done': {
        transform: `translateY(-${theme.other.spacing._32}px)`,
        opacity: 0,
      },
    }),
  },
  closeButton: {
    position: 'absolute',
    top: theme.other.spacing._16,
    right: theme.other.spacing._16,
    backgroundColor: theme.colors.gray[0],
    borderRadius: '100em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.other.spacing._4,
    cursor: 'pointer',
    transition: 'opacity 150ms ease-in-out',
    zIndex: 1,

    '&:hover': {
      opacity: 0.5,
    },
  },
  wrapperContent: {

  },
  containerInner: {
    position: 'relative',
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    ...(hasWrapperContent ? {
      backgroundColor: theme.white,
      borderRadius: theme.other.spacing._24,
    } : {}),
  },
}));

type DialogModalProps = DialogComponentProps & {
  fullScreen?: boolean;
}

function DialogModal({
  children,
  onClose,
  fullScreen,
  wrapperContent,
  isOpen,
  isClosing,
  className,
  dataTestId,
}: DialogModalProps) {
  const { classes, cx } = useStyles({
    hasWrapperContent: !!wrapperContent,
    fullScreen,
    isClosing,
    isOpen,
  });
  const ref = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      in={isOpen}
      appear
      timeout={TIMEOUT}
      classNames={{
        appear: 'enter',
        appearActive: 'enter-active',
        appearDone: 'enter-done',
        enter: 'enter',
        enterActive: 'enter-active',
        enterDone: 'enter-done',
        exit: 'exit',
        exitActive: 'exit-active',
        exitDone: 'exit-done',
      }}
      nodeRef={ref}
    >
      <div
        ref={ref}
        className={cx(classes.modal, className)}
        data-test-id={dataTestId}
      >
        {wrapperContent && <div className={classes.wrapperContent}>{wrapperContent}</div>}
        <div className={classes.containerInner}>
          {onClose && (
            <UnstyledButton className={classes.closeButton} onClick={onClose}>
              <IconX />
            </UnstyledButton>
          )}
          {children}
        </div>
      </div>
    </CSSTransition>
  )
}

export default DialogModal;
