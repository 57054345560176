import {
  ActionIcon,
  Center,
  Group,
  Input,
  NumberInput,
  Select,
  Skeleton,
  Space,
  Stack,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo, useState } from 'react';
import { useIsMutating } from '@tanstack/react-query';

import { FEATURE } from '~/common/enums/feature.enum';
import type { PaymentMethod } from '~/common/enums/payments.enum';
import { PAYMENT_TYPE_TO_METHOD, PaymentType, TransactionType } from '~/common/enums/payments.enum';
import { reportEvent } from '~/domains/analytics';
import { SignUpHeader } from '~/domains/auth/components/SignUpHeader/SignUpHeader';
import { ErrorAlert } from '~/domains/common/components/Alerts/Alerts';
import { Link } from '~/domains/common/components/Link/Link';
import { Tooltip } from '~/domains/common/components/Tooltip/Tooltip';
import { formatDollars } from '~/domains/common/utils/formatters';
import { Button } from '~/domains/design-system/Button';
import { Text } from '~/domains/design-system/Text';
import BillingAddressFormSection from '~/domains/forms/components/BillingAddressFormSection/BillingAddressFormSection';
import { paymentTypeOptions } from '~/domains/payments/constants';
import { usePaymentMethods } from '~/domains/payments/hooks/usePaymentMethods';
import CardTypeIcon from '~/domains/payments/icons/CardTypeIcon';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { PaymentType as WalletPaymentType } from '~/services/wallet/interfaces/wallets.interface';
import dt from '~/testing';
import { IconChevronLeft } from '~/domains/design-system/icons/IconChevronLeft';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import { Banner, BannerType } from '~/domains/common/components/Banner/Banner';

import { useAvailablePaymentMethods } from '../../hooks/useAvailablePaymentMethods';
import { getIconColor, isNewCardOption } from '../../utils/utils';
import { PaymentsQueryKeys } from '../../query';

import Verification from './Verification';
import { CardDetailsFormSection } from './CardDetailsFormSection';
import { CardSelect } from './CardSelect';
import { DepositMethods } from './DepositMethods';
import { DepositOptionRadio } from './DepositOptionRadio';
import {
  DepositOption,
  NEW_CARD_OPTION,
  depositOptionToValue,
  depositOptions,
  legalStatesOptions,
} from './consts';
import { useCountryOptions } from './useCountryOptions';
import useDepositForm from './useDepositForm';

export function DepositForm({
  contestIdForPostDeposit,
  setCompletedDepositAmount,
  fallbackUrl,
}: {
  contestIdForPostDeposit?: string;
  setCompletedDepositAmount: (amount: number) => void;
  fallbackUrl?: string;
}) {
  const { t } = useTranslation('payments');
  const theme = useMantineTheme();
  const countryOptions = useCountryOptions();
  const isMobile = useIsMobile();

  const isNewDepositUIEnabled = useIsFeatureEnabled(FEATURE.ENABLE_DEPOSIT_FACELIFT);
  const isAeropayEnabled = useIsFeatureEnabled(FEATURE.ENABLE_AEROPAY);

  const { form, handleSubmit, cardDetailsRef, isDepositing, depositAmountWithFees } =
    useDepositForm({
      contestIdForPostDeposit,
      setCompletedDepositAmount,
      fallbackUrl,
    });

  const isCreatingBankAccountLink = Boolean(
    useIsMutating([PaymentsQueryKeys.CREATE_BANK_ACCOUNT_LINK])
  );
  const isDepositingWithAeropay = Boolean(
    useIsMutating([PaymentsQueryKeys.CREATE_AEROPAY_DEPOSIT])
  );

  const isSubmitting = isDepositing || isCreatingBankAccountLink || isDepositingWithAeropay;

  const [isNextStep, setIsNextStep] = useState(false);
  const [aeropayChallengeRequired, setAeropayChallengeRequired] = useState(true);
  const [aeropayVerified, setAeropayVerified] = useState(false);

  const { values, setFieldValue } = form;
  const { cardOption, depositOption, paymentType, bankAccountId } = values;

  const linkBankViaAeropay = paymentType === 'AEROPAY' && !bankAccountId;

  const { data: paymentMethods, isLoading: isLoadingSavedCards } = usePaymentMethods({
    transactionType: TransactionType.DEPOSIT,
    paymentType: WalletPaymentType.CARD,
  });

  const cardOptions = useMemo(() => {
    const defaultOption = [
      {
        value: NEW_CARD_OPTION,
        label: t('addNewCard'),
      },
    ];

    return paymentMethods
      ? [
          ...paymentMethods.map((item) => ({
            value: item.id,
            lastDigits: item.card.lastDigits,
            expMonth: item.card.cardExpiry.month,
            expYear: item.card.cardExpiry.year,
            cardType: item.card.cardType,
            label: t('cardSelectLabel', {
              lastDigits: item.card.lastDigits,
              expMonth: item.card.cardExpiry.month,
              expYear: item.card.cardExpiry.year,
            }),
          })),
          ...defaultOption,
        ]
      : defaultOption;
  }, [paymentMethods, t]);

  // Cannot use Mantine's useDidUpdate, because this has to run onMount as well
  useEffect(() => {
    if (
      // No card selected yet
      (!cardOption ||
        // Switching payment types (de-select credit/debit card and pick a new default)
        !cardOptions.some(({ value }) => value === cardOption)) &&
      !isLoadingSavedCards
    ) {
      setFieldValue('cardOption', cardOptions[0]?.value);
    }
  }, [cardOptions, cardOption, setFieldValue, isLoadingSavedCards]);

  const { availablePaymentMethods, isLoadingAvailablePaymentMethods } = useAvailablePaymentMethods({
    transactionType: TransactionType.DEPOSIT,
  });

  const isCurrentPaymentTypeDisabled = availablePaymentMethods?.some(
    (item) =>
      item.paymentMethod === PAYMENT_TYPE_TO_METHOD[paymentType] && item.status === 'disabled'
  );

  const formattedAmountWithFees = Number.isNaN(depositAmountWithFees)
    ? ''
    : formatDollars(depositAmountWithFees);

  const currentCardType = useMemo(
    () => paymentMethods?.find((item) => item.id === cardOption)?.card.cardType,
    [cardOption, paymentMethods]
  );

  const isSelectCardVisible = paymentType === PaymentType.CARD;
  const isNewCardFormVisible = isSelectCardVisible && isNewCardOption(cardOption);
  const isSavedCardFormVisible =
    isSelectCardVisible && !!cardOption && !isNewCardOption(cardOption);
  const isSubmitButtonDisabled =
    isCurrentPaymentTypeDisabled || isLoadingAvailablePaymentMethods || !depositAmountWithFees;

  const showVIPP = useIsFeatureEnabled(FEATURE.SHOW_VIPP_AND_PAPER_CHECK);
  const showVenmo = useIsFeatureEnabled(FEATURE.ENABLE_VENMO);

  const getPaymentMessage = useMemo(() => {
    if (paymentType === PaymentType.PAYPAL) {
      return t('deposits.form.youWillBeRedirectedToPaypal');
    }

    if (formattedAmountWithFees && !linkBankViaAeropay) {
      return t('deposits.form.youWillBeChargedBySplash', {
        amount: formattedAmountWithFees,
      });
    }

    return null;
  }, [paymentType, linkBankViaAeropay, formattedAmountWithFees, t]);

  const getPaymentDescription = useMemo(
    () =>
      t(
        linkBankViaAeropay ? 'deposits.form.details.copyLinkAeropay' : 'deposits.form.details.copy'
      ),
    [linkBankViaAeropay, t]
  );

  const filteredPaymentTypes = useMemo(
    () =>
      paymentTypeOptions
        .filter((option) => {
          const availablePaymentMethod = availablePaymentMethods?.find(
            (method) => method.paymentMethod === (option as unknown as PaymentMethod) // FIXME: availablePaymentMethods should be typed correctly, but that requires a larger refactor
          );

          if (!availablePaymentMethod) {
            return false;
          }

          return availablePaymentMethod.status !== 'hidden';
        })
        .filter((option) =>
          showVIPP
            ? option !== PaymentType.PAPER_CHECK
            : ![PaymentType.PAPER_CHECK, PaymentType.VIPPREFERRED].includes(option)
        )
        .filter((option) => showVenmo || option !== PaymentType.VENMO),
    [availablePaymentMethods, showVIPP, showVenmo]
  );

  // instead of setting default paymentType in initialValue (which can be disabled or hidden), we select the first available payment type
  useEffect(() => {
    if (isNewDepositUIEnabled) {
      return;
    }

    if (!paymentType && filteredPaymentTypes.length > 0) {
      form.setFieldValue('paymentType', filteredPaymentTypes[0]);
    }
  }, [filteredPaymentTypes, form, isNewDepositUIEnabled, paymentType]);

  if (!isLoadingAvailablePaymentMethods && filteredPaymentTypes.length === 0) {
    return (
      <ErrorAlert px="lg" mt="xs">
        <Trans
          i18nKey="payments:deposits.errors.noAvailableMethods"
          components={{
            customerSupportLink: (
              <Link
                href="mailto:support@splashsports.com?subject=Splash Support - Deposit Issues&body=Hello, Splash Support"
                target="_blank"
                style={{ color: 'inherit', textDecoration: 'underline' }}
              />
            ),
          }}
        />
      </ErrorAlert>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={isNewDepositUIEnabled ? 32 : undefined}>
        {
          // Screen One
          // eslint-disable-next-line no-nested-ternary
          !isNextStep ? (
            <>
              <SignUpHeader
                title={t('deposits.form.paymentType.title')}
                copy={t('deposits.form.paymentType.copy')}
                isLogoVisible={false}
              />
              <DepositMethods
                availablePaymentMethods={availablePaymentMethods}
                bankAccountId={form.values.bankAccountId}
                filteredPaymentTypes={filteredPaymentTypes}
                isLoading={isLoadingAvailablePaymentMethods}
                paymentType={paymentType}
                onChange={(type, currentAccountId) => {
                  form.setFieldValue('paymentType', type);
                  form.setFieldValue('bankAccountId', currentAccountId);
                  reportEvent('Transactions > Switch Deposit Method', { deposit_method: type });
                }}
                isNewDepositUIEnabled={isNewDepositUIEnabled}
                isAeropayEnabled={isAeropayEnabled}
              />
              <Tooltip
                withArrow
                isRendered={!paymentType}
                label={t('withdrawal.form.actions.tooltip.paymentType')}
                data-test-id={dt.payments.deposits.depositForm.continueButton}
              >
                <Button
                  data-test-id={dt.payments.deposits.depositForm.continueButton}
                  variant={isNewDepositUIEnabled ? 'primary-fill' : 'secondary-fill'}
                  size="large"
                  type="button"
                  disabled={!paymentType}
                  fullWidth
                  onClick={() => {
                    setIsNextStep(true);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  // data-test-id={dt.payments.deposits.depositForm.continue}
                >
                  {t('deposits.form.continue')}
                </Button>
              </Tooltip>
              <Center>
                <Text
                  size={14}
                  color={theme.colorScheme === 'light' ? theme.black : theme.colors.gray[4]}
                  data-test-id={dt.payments.deposits.depositForm.youWillBeChargedBySplash}
                >
                  {!isNewDepositUIEnabled && getPaymentMessage}
                </Text>
              </Center>
            </>
          ) : // Screen Two - Verification
          linkBankViaAeropay && aeropayChallengeRequired && !aeropayVerified ? (
            <>
              <ActionIcon
                onClick={() => setIsNextStep(false)}
                {...(!isMobile
                  ? { pos: 'absolute', top: 0, left: -theme.other.spacing._32 * 2 }
                  : {})}
                h={32}
                w={32}
              >
                <IconChevronLeft size={32} />
              </ActionIcon>
              <Verification
                setAeropayChallengeRequired={setAeropayChallengeRequired}
                setAeropayVerified={setAeropayVerified}
              />
            </>
          ) : (
            // Screen Three
            <>
              <div>
                <ActionIcon
                  onClick={() => setIsNextStep(false)}
                  {...(!isMobile
                    ? { pos: 'absolute', top: 0, left: -theme.other.spacing._32 * 2 }
                    : {})}
                  h={32}
                  w={32}
                >
                  <IconChevronLeft size={32} />
                </ActionIcon>

                <SignUpHeader
                  title={t('deposits.form.details.title')}
                  copy={getPaymentDescription}
                  isLogoVisible={false}
                />
              </div>

              <Input.Wrapper label={t('deposits.form.amount.label')}>
                <Group sx={{ gap: '8px', marginTop: '16px' }}>
                  {depositOptions.map((option, index) => (
                    <DepositOptionRadio
                      key={option}
                      value={option}
                      isActive={option === depositOption}
                      onChange={() => {
                        form.setFieldValue('depositOption', option);
                        reportEvent('Transactions > Switch Deposit Amount', {
                          deposit_method: form.getInputProps('paymentType'),
                          deposit_amount: option,
                        });
                      }}
                      data-test-id={dt.payments.deposits.depositForm.depositOption}
                    >
                      {option === DepositOption.CUSTOM
                        ? t('deposits.form.amount.custom')
                        : formatDollars(depositOptionToValue[option])}
                    </DepositOptionRadio>
                  ))}
                </Group>
              </Input.Wrapper>

              {depositOption === DepositOption.CUSTOM && (
                <NumberInput
                  {...form.getInputProps('customAmount')}
                  precision={2}
                  placeholder={t('deposits.form.amount.placeholder')}
                  autoFocus
                  hideControls
                  size="lg"
                  data-test-id={dt.payments.deposits.depositForm.customAmountInput}
                  inputMode="decimal"
                />
              )}

              {isSelectCardVisible && (
                <Input.Wrapper label={t('deposits.form.card.label')}>
                  <Skeleton visible={isLoadingSavedCards}>
                    <Select
                      styles={{
                        itemsWrapper: {
                          gap: '8px',
                          backgroundColor: theme.colors.gray[2],
                          padding: theme.spacing.md,
                        },
                      }}
                      value={cardOption}
                      data={cardOptions}
                      size="lg"
                      data-test-id={dt.payments.deposits.depositForm.cardSelect.select}
                      icon={<CardTypeIcon type={currentCardType} color={getIconColor(theme)} />}
                      onChange={(value) => form.setFieldValue('cardOption', value ?? undefined)}
                      withinPortal
                      itemComponent={CardSelect}
                    />
                  </Skeleton>
                </Input.Wrapper>
              )}

              {isNewCardFormVisible && (
                <>
                  <Space h="sm" />
                  <CardDetailsFormSection form={form} ref={cardDetailsRef} />
                  <Space h="sm" />
                  <BillingAddressFormSection
                    form={form}
                    stateOptions={legalStatesOptions}
                    countryOptions={countryOptions}
                    withSameAddressCheckbox
                  />
                </>
              )}

              {isSavedCardFormVisible && (
                <TextInput
                  {...form.getInputProps('confirmCVV')}
                  onChange={(event) => {
                    form.setFieldValue(
                      'confirmCVV',
                      event.target.value.replace(/\D+/g, '').slice(0, 4)
                    );
                  }}
                  placeholder={t('deposits.form.confirmCVV.placeholder')}
                  data-test-id={dt.payments.deposits.depositForm.cvvInput}
                  label={t('deposits.form.confirmCVV.label')}
                  labelProps={{ size: 'sm' }}
                  description={t('deposits.form.confirmCVV.description')}
                  descriptionProps={{ size: 'md' }}
                  withAsterisk
                  size="lg"
                />
              )}

              <Space h="xl" />

              {isCreatingBankAccountLink && (
                <Banner
                  type={BannerType.INFO}
                  minimal={false}
                  title={t('deposits.aeropay.creatingBankAccountLink.title')}
                  message={t('deposits.aeropay.creatingBankAccountLink.message')}
                />
              )}

              {isDepositingWithAeropay && (
                <Banner
                  type={BannerType.INFO}
                  minimal={false}
                  title={t('deposits.aeropay.createDeposit.title')}
                  message={t('deposits.aeropay.createDeposit.message')}
                />
              )}

              <Button
                variant={isNewDepositUIEnabled ? 'primary-fill' : 'secondary-fill'}
                size="large"
                disabled={isSubmitButtonDisabled}
                type="submit"
                fullWidth
                loading={isSubmitting}
                data-test-id={dt.payments.deposits.depositForm.submitButton}
              >
                {!linkBankViaAeropay
                  ? t('deposits.form.deposit', { amount: formattedAmountWithFees })
                  : t('deposits.aeropay.continueWithAeropay')}
              </Button>
              {!isSubmitButtonDisabled && (
                <Center>
                  <Text
                    size={14}
                    color={theme.colorScheme === 'light' ? theme.black : theme.colors.gray[4]}
                    data-test-id={dt.payments.deposits.depositForm.youWillBeChargedBySplash}
                  >
                    {getPaymentMessage}
                  </Text>
                </Center>
              )}
            </>
          )
        }
      </Stack>
    </form>
  );
}
