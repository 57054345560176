import { createStyles } from "@mantine/core";
import { ComponentProps, useEffect, useMemo } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useScrollLock } from "@mantine/hooks";

import { useIsMobile } from "~/domains/common/hooks/mediaQueries";

import DialogBackdrop from "./DialogBackdrop/DialogBackdrop";
import DialogModal from "./DialogModal/DialogModal";
import DialogDrawer from "./DialogDrawer/DialogDrawer";
import type { DialogComponentProps } from "./types";
import { TIMEOUT } from "./constants";


const useStyles = createStyles((theme) => ({
  backdropOnEnter: {
    opacity: 0,
  },
  backdropOnEnterActive: {
    opacity: 1,
    transition: `opacity ${TIMEOUT}ms ease-in-out`,
  },
  backdropOnEnterDone: {
    opacity: 1,
  },
  backdropOnExit: {
    opacity: 1,
  },
  backdropOnExitActive: {
    opacity: 0,
    transition: `opacity ${TIMEOUT}ms ease-in-out`,
  },
}));

type DialogProps = {
  isOpen?: boolean;
  onClose?: () => void;
  lockScroll?: boolean;
  children: React.ReactNode;
  className?: string;
}

function DialogContainer({
  isOpen = true,
  onClose,
  lockScroll = false,
  children,
  className,
}: DialogProps) {
  const { classes } = useStyles();
  const [, setScrollLocked] = useScrollLock(false);

  useEffect(() => {
    if (lockScroll && isOpen) {
      setScrollLocked(true);
    } else {
      setScrollLocked(false);
    }
  }, [lockScroll, isOpen]);

  return (
    <CSSTransition
      in={isOpen}
      timeout={TIMEOUT}
      classNames={{
        enter: classes.backdropOnEnter,
        enterActive: classes.backdropOnEnterActive,
        enterDone: classes.backdropOnEnterDone,
        exit: classes.backdropOnExit,
        exitActive: classes.backdropOnExitActive,
      }}
      unmountOnExit
    >
      <DialogBackdrop onClick={onClose} className={className}>
        {children}
      </DialogBackdrop>
    </CSSTransition>
  )
}

export default DialogContainer;
