export const enum URLQueryKeys {
  // Filters
  /** @deprecated - we should be using league instead */
  SPORT = 'sports',
  LEAGUE = 'league',
  VIEW = 'view',
  ENTRY_STATUS = 'entryStatus',
  CONTEST_STATUS = 'contestStatus',
  CONTEST_TYPE = 'contestType',
  PAYOUT_FORMAT = 'payoutFormats',
  ENTRY_FEE = 'entryFee',
  SORT_BY = 'sortBy',
  SORT_ORDER = 'sortByOrder',
  COMMISSIONER_ID = 'commissionerId',
  HAS_ENTRY_FEE = 'hasEntryFee',

  // Routing params
  REDIRECT_TO = 'redirectTo',
  BRAND = 'brand',
  CONTEST_ID = 'contestId',
  ENTRY_ID = 'entryId',
  SLATE_ID = 'slateId',
  REF = 'ref',
  EDITING = 'editingPicks',
  IS_NEW_ENTRY = 'isNewEntry',
  UTM_SOURCE = 'utm_source',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_MEDIUM = 'utm_medium',
  UTM_CONTENT = 'utm_content',
  UTM_PARAM_CONTEST_ID = 'utm_param_contest_id',
  REF_PLATFORM = 'refPlatform',
  ENTER_CONTEST = 'enterContest',
  ACTIVE_TAB = 'activeTab',
  SEARCH_QUERY = 'q',
  CHANNEL_FOLLOWING = 'following',
  CHANNEL_FOLLOWERS = 'followers',

  // Invite Manager
  INVITES_MANAGER_FOLLOWERS = 'followers',
  INVITES_MANAGER_GROUPS = 'groups',
  INVITES_MANAGER_EMAIL = 'email',
  INVITES_MANAGER_RYP = 'RYP',

  // Refer a friend
  REFERRAL_CODE = 'referralCode',

  // QuickPicks
  // LEAGUE = 'league',
  GAME = 'game',
  TYPE = 'type',
}
