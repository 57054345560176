import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { FEATURE } from '~/common/enums/feature.enum';
import { useTreatment } from '~/domains/common/hooks/useTreatment';

import { BRANDS } from '../consts';

export const useGetBranding = (): {
  isLoading: boolean;
  brand?: BRANDS;
} => {
  const {
    treatment: { treatment },
    isReady,
  } = useTreatment(FEATURE.ENABLE_UNIFIED_LOGIN);
  const { query } = useRouter();
  const brand = query.brand as BRANDS | undefined;
  const isUnifiedLoginEnabled = treatment === 'on';

  const path = usePathname();
  const isAuthPath = ['/sign-up', '/sign-in'].includes(path);
  const uppercaseBrand = brand?.toUpperCase();

  if (!isReady) {
    return { isLoading: true, brand: undefined };
  }
  if (!isAuthPath || !brand || !isUnifiedLoginEnabled) {
    return { isLoading: false, brand: BRANDS.SPLASH };
  }
  if (uppercaseBrand === 'RYP') {
    return { isLoading: false, brand: BRANDS.RYP };
  }
  if (uppercaseBrand === 'OFP') {
    return { isLoading: false, brand: BRANDS.OFP };
  }
  return { isLoading: false, brand: BRANDS.SPLASH };
};
