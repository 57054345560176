import { createStyles } from "@mantine/core";
import { CSSTransition } from "react-transition-group";
import { useRef } from "react";

import { UnstyledButton } from "~/domains/design-system/Button";
import IconX from "~/domains/design-system/icons/IconX";

import type { DialogComponentProps } from "../types";
import { TIMEOUT } from "../constants";

const useStyles = createStyles((theme, {
  hasWrapperContent,
  isClosing,
  isOpen,
}: {
  hasWrapperContent: boolean;
  isClosing: boolean;
  isOpen: boolean;
}) => ({
  container: {
    position: 'relative',
    width: '100%',
    backgroundColor: hasWrapperContent ? theme.colors.gray[0] : theme.white,
    borderRadius: `${theme.other.spacing._24}px ${theme.other.spacing._24}px 0 0`,
    alignSelf: 'end',
    display: (() => {
      if (isOpen || isClosing) {
        return hasWrapperContent ? 'block' : 'flex';
      }

      return 'none';
    })(),

    '&.enter': {
      transform: 'translateY(100%)',
    },
    '&.enter-active': {
      transform: 'translateY(0)',
      transition: `transform ${TIMEOUT}ms ease-in-out`,
    },
    '&.enter-done': {
      transform: 'translateY(0)',
    },
    '&.exit': {
      transform: 'translateY(0)',
    },
    '&.exit-active': {
      transform: 'translateY(100%)',
      transition: `transform ${TIMEOUT}ms ease-in-out`,
    },
    '&.exit-done': {
      transform: 'translateY(100%)',
    },
  },
  closeButton: {
    position: 'absolute',
    top: theme.other.spacing._16,
    right: theme.other.spacing._16,
    backgroundColor: theme.colors.gray[0],
    borderRadius: '100em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.other.spacing._4,
    cursor: 'pointer',
    transition: 'opacity 150ms ease-in-out',
    zIndex: 1,

    '&:hover': {
      opacity: 0.5,
    },
  },
  wrapperContent: {},
  containerInner: {
    position: 'relative',
    width: '100%',
    ...(hasWrapperContent ? {
      backgroundColor: theme.white,
      borderRadius: `${theme.other.spacing._24}px ${theme.other.spacing._24}px 0 0`,
    } : {}),
  },
}));

function DialogDrawer({
  children,
  wrapperContent,
  isOpen,
  isClosing,
  onClose,
}: DialogComponentProps) {
  const { classes } = useStyles({
    hasWrapperContent: !!wrapperContent,
    isClosing,
    isOpen,
  });
  const ref = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      in={isOpen}
      appear
      timeout={TIMEOUT}
      classNames={{
        appear: 'enter',
        appearActive: 'enter-active',
        appearDone: 'enter-done',
        enter: 'enter',
        enterActive: 'enter-active',
        enterDone: 'enter-done',
        exit: 'exit',
        exitActive: 'exit-active',
        exitDone: 'exit-done',
      }}
      nodeRef={ref}
    >
      <div
        ref={ref}
        className={classes.container}
      >
        {wrapperContent && <div className={classes.wrapperContent}>{wrapperContent}</div>}
        <div className={classes.containerInner}>
          {onClose && (
            <UnstyledButton className={classes.closeButton} onClick={onClose}>
              <IconX />
            </UnstyledButton>
          )}
          {children}
        </div>
      </div>
    </CSSTransition>
  );
}

export default DialogDrawer;
