import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import useTranslation from 'next-translate/useTranslation';

import Dialog from '~/domains/design-system/components/Dialog/Dialog';
import DialogBody from '~/domains/design-system/components/Dialog/DialogBody/DialogBody';
import { SplashLogo } from '~/components/shared/Logos/SplashLogo';
import { Text } from '~/domains/design-system/Text';

import tc from '../../testing.const';
import type { FullContest } from '../../../services/interfaces/full-contest.interface';

import EnterContestScreen from './EnterContestScreen/EnterContestScreen';
import EnterContestLoadingScreen from './EnterContestLoadingScreen/EnterContestLoadingScreen';
import EnterContestSubmittingScreen from './EnterContestSubmittingScreen/EnterContestSubmittingScreen';
import useStyles from './EnterContestModal.styles';

type AddEntriesModalProps = {
  availableBalance: number;
  contest: FullContest;
  createEntriesErrorMessage: string;
  handleCreateEntries: (entriesCount: number) => void;
  handleOpenDepositModal: () => void;
  isCloseDisabled: boolean;
  isCreateEntriesReady: boolean;
  isOpen: boolean;
  isRestartedContest?: boolean;
  nodeRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
  state: string;
};

function EnterContestModal({
  availableBalance,
  contest,
  createEntriesErrorMessage,
  handleCreateEntries,
  handleOpenDepositModal,
  isCloseDisabled,
  isCreateEntriesReady,
  isOpen,
  isRestartedContest,
  nodeRef,
  onClose,
  state,
}: AddEntriesModalProps) {
  const { classes, theme } = useStyles();
  const { t } = useTranslation('contest');

  return (
    <Dialog
      isOpen={isOpen}
      onClose={isCloseDisabled ? undefined : onClose}
      type={{
        desktop: 'modal',
        mobile: 'drawer',
      }}
      wrapperContent={isRestartedContest && (
        <div className={classes.restartedContestWrapper}>
          <SplashLogo color="#1a1a1a" size={20} />
          <Text variant="body-medium" color={theme.colors.gray2[4]} className={classes.restartedContestText}>
            {t('common.enterContestModal.enterContest.splashHostedMigrationMessage', { templateName: contest.contest_template_name})}
          </Text>
        </div>
      )}
      dataTestId={tc.enterContestModal.modal}
    >
      <DialogBody className={classes.body}>
        <SwitchTransition>
          <CSSTransition
            key={state}
            nodeRef={nodeRef}
            // @ts-expect-error typescript definition defies their documentation
            addEndListener={(node, done) => {
              if (typeof node === 'function') {
                return;
              }
              node?.addEventListener('transitionend', done, false);
            }}
            classNames={{
              enter: classes.contentEnter,
              enterActive: classes.contentEnterActive,
              exit: classes.contentExit,
              exitActive: classes.contentExitActive,
            }}
            timeout={300}
          >
            <div ref={nodeRef} className={classes.content}>
              {(() => {
                switch (state) {
                  case 'loading': {
                    return <EnterContestLoadingScreen />;
                  }
                  case 'success':
                  case 'submitting': {
                    return <EnterContestSubmittingScreen />;
                  }
                  default: {
                    return (
                      <EnterContestScreen
                        balanceInDollars={availableBalance}
                        contest={contest}
                        errorMessage={createEntriesErrorMessage}
                        onClose={onClose}
                        onCreateEntries={handleCreateEntries}
                        onDepositClick={handleOpenDepositModal}
                        isCreateEntriesReady={isCreateEntriesReady}
                      />
                    );
                  }
                }
              })()}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </DialogBody>
    </Dialog>
  );
}

export default EnterContestModal;
