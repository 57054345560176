/** @deprecated - Use new RootModalsContext instead */
import { Fragment, useContext } from 'react';
import { match } from 'ts-pattern';

import InviteModal from '~/domains/contest/domains/invite/components/InviteModal';

import { RootModalsContext } from './RootModalsContext';

function RootModals() {
  const { modals, closeModal } = useContext(RootModalsContext);

  return (
    <>
      {modals.map((modal) => (
        <Fragment key={modal.type}>
          {match(modal)
            .returnType<React.ReactNode>()
            .with({ type: 'InviteModal' }, (rest) => (
              <InviteModal opened {...rest} onClose={closeModal} />
            ))
            .otherwise(() => null)}
        </Fragment>
      ))}
    </>
  );
}

export default RootModals;
