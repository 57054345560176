import { Button, Modal, createStyles } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useContext, useState } from 'react';

import { Text } from '~/domains/design-system/Text';
import { Drawer } from '~/domains/design-system/components/Drawer/Drawer';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { FEATURE } from '~/common/enums/feature.enum';
import { getCdnUrl } from '~/domains/assets/utils/getCdnUrl';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import { UnstyledButton } from '~/domains/design-system/Button';
import dt from '~/testing';
import { reportEvent } from '~/domains/analytics';
import { UserContext } from '~/components/providers/UserProvider';

import { RAFDrawer } from './RAFDrawer';

const useClasses = createStyles((theme) => ({
  overlay: {
    zIndex: theme.other.zIndex.referAFriendDrawer,
  },
  content: {
    zIndex: theme.other.zIndex.referAFriendDrawer + 1,
    maxWidth: '420px',
  },
  close: {
    display: 'none',
  },
}));

const REFER_AMOUNT = '$10'; // in the future this might be dynamic

export function RAFBanner({
  className,
  text,
  order,
}: {
  className?: string;
  text?: string;
  order?: number;
}) {
  const { t } = useTranslation('user');
  const { user } = useContext(UserContext);

  const [toggle, onToggle] = useState(false);
  const { classes } = useClasses();
  const isMobile = useIsMobile();

  const isReferAFriendEnabled = useIsFeatureEnabled(FEATURE.ENABLE_REFER_A_FRIEND_V2);

  const handleOpen = useCallback(() => {
    onToggle(true);
    reportEvent('RAF > Open Modal', {
      raf_code: user?.referralCode,
    });
  }, [user?.referralCode]);

  const handleClose = useCallback(() => {
    onToggle(false);
  }, []);

  if (!isReferAFriendEnabled) {
    return null;
  }

  return (
    <div style={{ order }}>
      {text ? (
        <UnstyledButton className={className} onClick={handleOpen}>
          {text}
        </UnstyledButton>
      ) : (
        <Button
          data-test-id={dt.common.components.banner.RAFbanner}
          onClick={handleOpen}
          variant="subtle"
          fullWidth
          p={16}
          sx={(theme) => ({
            height: '56px',
            borderRadius: '8px',
            textAlign: 'center',
            backgroundColor: theme.colors.gray2[7],
            backgroundImage: `url(${
              isMobile
                ? getCdnUrl('/images/logos/raf-banner-bg-mobile.jpg')
                : getCdnUrl('/images/logos/raf-banner-bg-desktop.jpg')
            })`,

            '&:hover': {
              backgroundColor: `${theme.colors.gray2[5]}`,
            },
          })}
        >
          <Text
            sx={(theme) => ({
              textAlign: 'center',
              fontSize: isMobile ? '14px' : '20px',
              fontWeight: 900,
              lineHeight: '20px',
              textTransform: 'uppercase',
              fontFamily: theme.other.fontFamily.hubotSans,
              background: 'linear-gradient(90deg, #4BEBE2 0%, #DA47F7 100%)',
              backgroundClip: 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
            })}
          >
            {t('referAFriend.banner', {
              amount: REFER_AMOUNT,
            })}
          </Text>
        </Button>
      )}

      {isMobile ? (
        <Drawer onClose={handleClose} open={toggle} title="" onToggle={onToggle} classes={classes}>
          <RAFDrawer amount={REFER_AMOUNT} onClose={handleClose} />
        </Drawer>
      ) : (
        <Modal
          opened={toggle}
          onClose={handleClose}
          centered
          size={420}
          styles={{
            modal: {
              borderRadius: '16px',
            },
          }}
        >
          <RAFDrawer amount={REFER_AMOUNT} onClose={handleClose} />
        </Modal>
      )}
    </div>
  );
}
