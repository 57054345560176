import { useMemo } from "react";

import { useIsMobile } from "~/domains/common/hooks/mediaQueries";

import DialogDrawer from "./DialogDrawer/DialogDrawer";
import DialogModal from "./DialogModal/DialogModal";
import type { DialogComponentProps , DialogType } from "./types";


const DialogComponents = {
  modal: (props: DialogComponentProps) => <DialogModal {...props} />,
  'modal-full-screen': (props: DialogComponentProps) => <DialogModal {...props} fullScreen />,
  drawer: (props: DialogComponentProps) => <DialogDrawer {...props} />,
}

function Dialog({
  type = 'modal',
  ...props
}: {
  type: DialogType | {
    mobile: DialogType;
    desktop: DialogType;
  };
} & DialogComponentProps) {
  const isMobile = useIsMobile();

  const dialogType = useMemo(() => {
    if (typeof type === 'string') {
      return type;
    }

    return isMobile ? type.mobile : type.desktop;
  }, [type, isMobile]);

  const DialogComponent = DialogComponents[dialogType];

  return <DialogComponent {...props} />;
}

export default Dialog;
