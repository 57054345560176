import { createStyles } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Trans from 'next-translate/Trans';
import Confetti from 'react-confetti';

import { Title } from '~/domains/design-system/Title';
import { Text } from '~/domains/design-system/Text';
import { IconCircleCheck } from '~/domains/design-system/icons/IconCircleCheck';
import Dialog from '~/domains/design-system/components/Dialog/Dialog';
import DialogBody from '~/domains/design-system/components/Dialog/DialogBody/DialogBody';
import { IS_STORYBOOK } from '~/config/const';
import { CurrencyConvertor } from '~/components/utils/formatters';

import { REDIRECT_TIMEOUT } from '../useEnterContestModal';


const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: 300,
    minWidth: '500px',

    [theme.fn.smallerThan('sm')]: {
      minWidth: '100%',
    },
  },
}));

function EnterContestSuccessScreen({
  entryCount,
  isOpen,
  onClose,
  redirectTo,
  balanceInDollars,
}: {
  entryCount: number;
  isOpen: boolean;
  onClose?: () => void;
  redirectTo: string;
  balanceInDollars?: number;
}) {
  const { t } = useTranslation('contest');
  const [remaining, setRemaining] = useState(REDIRECT_TIMEOUT);
  const timerRef = useRef<NodeJS.Timeout>();
  const { classes, theme } = useStyles();
  const router = useRouter();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setRemaining((prev) => {
        const nextValue = prev - 1000;
        if (nextValue > 0) { return nextValue; }

        clearInterval(timerRef.current);
        if (IS_STORYBOOK) { return 0; }

        router.push(redirectTo);
        onClose?.();

        return 0;
      });
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  return (
    <>
      {isOpen && <Confetti recycle={false} />}
      <Dialog
        type={{ desktop: 'modal', mobile: 'modal-full-screen' }}
        isOpen={isOpen}
      >
        <DialogBody className={classes.container}>
          <IconCircleCheck color={theme.colors.green[3]} />
          <Title variant="headline-xl" color={theme.colors.gray2[6]}>
            {t('common.enterContestModal.success.title')}
          </Title>
          <Text variant="body-large" color={theme.colors.gray2[4]}>
            {t('common.enterContestModal.success.text__hasPlural', {
              count: entryCount,
            })}
          </Text>
          {typeof balanceInDollars === 'number' && (
            <Text variant="body-large" color={theme.colors.gray2[4]} mt={theme.other.spacing._8}>
              <Trans
                i18nKey="contest:common.enterContestModal.success.balance"
                values={{
                  balance: CurrencyConvertor(balanceInDollars),
                }}
                components={{
                  balance: (
                    <Text variant="headline-large" color={theme.colors.gray2[4]} mt={theme.other.spacing._8} />
                  ),
                }}
              />
            </Text>
          )}
          <Text variant="title-small" color={theme.colors.gray2[3]} mt={theme.other.spacing._16}>
            {t('common.enterContestModal.success.redirecting', {
              seconds: remaining / 1000,
            })}
          </Text>
        </DialogBody>
      </Dialog>
    </>
  );
}

export default EnterContestSuccessScreen;
