import { PaymentType } from '~/common/enums/payments.enum';
import { MessageKeys } from '~/domains/payments/deposits/components/consts';

export default {
  addNewCard: 'Add a New Card',
  paymentTypes: {
    DEBIT_CARD: 'Debit Card', // a special case for withdrawal
    [PaymentType.AEROPAY]: 'Online Banking',
    [PaymentType.CARD]: 'Debit/Credit',
    [PaymentType.PAYPAL]: 'PayPal',
    [PaymentType.VIPPREFERRED]: 'Online Banking',
    [PaymentType.PAPER_CHECK]: 'Check',
    [PaymentType.VENMO]: 'Venmo',
    [PaymentType.SKRILL]: 'Skrill',
  },
  cardSelectLabel: 'Ending in {{lastDigits}} | Exp {{expMonth}}/{{expYear}}',
  cardSelect: {
    label: 'Ending in {{lastDigits}}',
    processingFee: '{{processingFee}}% processing fee',
    expiration: 'Exp {{expMonth}}/{{expYear}}',
  },
  deleteCardModal: {
    removeCard: 'Remove card',
    removeCardCopy: 'Are you sure you want to remove your card ending in {{lastDigits}}?',
    cancel: 'Cancel',
    remove: 'Remove',
    success: { message: 'Card deleted successfully.' },
    error: {
      title: 'Error',
      message: 'We were unable to delete this saved card. Please contact customer support.',
    },
  },
  currentBalance: 'Current Balance',
  withdrawableBalance: 'Withdrawable Balance',
  withdrawableBalanceTooltipTitle: 'Withdrawable Balance',
  withdrawableBalanceTooltipText:
    'Does not include credits from Splash Sports. Credits are non-withdrawable and can only be used for entries and gameplay.',
  credits: 'Credits',
  creditsTooltipTitle: 'Credits',
  creditsTooltipText: 'Non-withdrawable funds provided by Splash Sports for gameplay only.',
  continue: 'Continue',
  transactionHistory: {
    noTransactions: 'No transactions found',
    pending: 'Pending Transactions',
    recent: 'Recent Transactions',
    all: 'All Transactions',
    type: 'Type',
    transId: 'Transaction ID',
    date: 'Date',
    balance: 'Balance',
  },
  deposits: {
    aeropay: {
      challenge: {
        error: {
          title: 'Aeropay Verify Account Error',
          message: 'There was an issue with the Aeropay verification. Please try again later.',
        },
      },
      bankAccountLinkCreated: {
        title: 'Bank account linked',
        message: 'Processing your deposit...',
      },
      continueWithAeropay: 'Continue to deposit',
      creatingBankAccountLink: {
        title: 'Creating bank account link',
        message: 'We are creating a bank account link for you. This will only take a moment.',
      },
      createDeposit: {
        title: 'Almost there!',
        message: 'We are processing your deposit.',
      },
      verify: {
        title: 'Verify your account',
        message:
          'You’ve previously used AeroPay to pay another business using this phone number. Please verify your identity by entering the pin sent to your email {{email}}',
      },
    },
    completed: {
      fundsAdded: `{{amount}} has been added to your account`,
      newBalance: `Your new balance is {{amount}}`,
      footerNote: `Please note: Some banks charge processing fees on credit card transactions.`,
    },
    savedDepositMethods: 'Saved deposit methods',
    otherDepositMethods: 'Other deposit methods',
    form: {
      title: 'Deposit Funds',
      paymentType: {
        label: 'Payment Type',
        title: 'DEPOSIT METHOD',
        copy: 'Select your deposit method. You will only be able to withdraw funds using methods that you’ve used to deposit funds.',
      },
      details: {
        title: 'TRANSACTION DETAILS',
        copy: 'Enter your deposit amount and transaction details.',
        copyLinkAeropay:
          "Enter your deposit amount. You'll be redirected to our trusted ACH partner, Aeropay, to link your online banking account.",
      },
      continue: 'Continue',
      amount: {
        label: 'Deposit Amount',
        custom: 'Custom',
        placeholder: 'Add Custom Amount',
        errors: {
          mustNotBeEmpty: 'Please specify a custom deposit amount',
          mustBeMoreThan: 'Deposit amount must be at least {{amount}}',
          mustBeLessThan: 'Deposit amount must be at most {{amount}}',
        },
      },
      card: {
        label: 'Select Card',
      },
      cardDetails: 'Card Details',
      cardholderName: {
        label: 'Cardholder Name',
        placeholder: 'Cardholder Name',
        error: 'Please enter a valid cardholder name',
      },
      cardNumber: {
        label: 'Card Number',
        placeholder: '1234 1234 1234 1234',
        error: 'Please enter a valid card number',
      },
      expDate: {
        label: 'Exp Date',
        placeholder: 'MM/YY',
        error: 'Please enter a valid expiration date',
      },
      cvv: {
        label: 'CVV',
        placeholder: '###',
        error: 'Please enter a valid CVV',
      },
      confirmCVV: {
        label: 'Confirm CVV',
        placeholder: '###',
        description: 'CVV is the 3 or 4 digit number on the back of your card',
        error: 'Please enter a valid CVV',
      },
      deposit: 'Deposit {{amount}}',
      youWillBeRedirectedToPaypal: 'You will be redirected to PayPal to complete your deposit',
      youWillBeChargedBySplash: 'You will be billed a total of {{amount}} by Splash Sports.',
    },
    warnings: {
      missingKYC: 'We need a little more information from you before you can deposit.',
    },
    errors: {
      default: '{{type}} Deposit Error',
      paypal: 'PayPal Deposit Error',
      skrill: 'Skrill Deposit Error',
      venmo: 'Venmo Deposit Error',
      savedCard: 'Saved Card Deposit Error',
      newCard: 'Card Deposit Error',
      vipp: 'VIPP Deposit Error',
      deposit: 'Deposit Error',
      paysafe: {
        incorrectAddressTitle: 'Incorrect Address',
        incorrectAddressMessage: 'Please ensure that the billing address is correct.',
        cardTypeNotSupportedTitle: 'Card type not supported',
        cardTypeNotSupportedMessage:
          'The card type that you have entered is not currently supported',
        generalTitle: 'Deposit Error',
        generalMessage:
          'Your deposit could not be processed. Please make sure the information entered is correct and contact support if you think there is an issue.',
      },
      blockedByFeatureFlag: 'Unfortunately, you are not currently allowed to make a deposit.',
      pleaseNote: 'Please note:',
      actionRequired: 'Action required!',
      blockedByLocation:
        '<pleaseNote/> Deposits can only be made from an <eligibleLocation/>. If you believe you’ve received this message in error, please contact <customerSupportLink/>.',
      locationServicesDisabled:
        '<actionRequired/> You must <locationServices/> to be able to make a deposit, so that we can verify you are in an <eligibleLocation/>.',
      unavailablePaymentType:
        'Unfortunately, depositing with {{type}} is not currently available at your location.',
      notifications: {
        [MessageKeys.REJECTED]: 'There was an issue processing your {{type}} deposit.',
      },
      links: {
        eligibleLocation: 'eligible location',
        customerSupport: 'customer support',
        locationServices: 'share your location',
      },
      noAvailableMethods:
        "Depositing isn't supported in your current location. Try refreshing the page. If you think this is incorrect, please <customerSupportLink>contact support</customerSupportLink>.",
      aeropay: {
        title: 'Deposit Error',
        general:
          'Your deposit could not be processed. Please make sure the information entered is correct and contact support if you think there is an issue.',
        maxAccounts:
          'You have reached the maximum number of Aeropay accounts. Remove an account to add a new one.',
      },
    },
    successPage: {
      fundsAdded: 'Funds Added',
      addedToAccount: 'has been added to your account',
      yourNewBalanceIsX: 'Your new balance is {{value}}*',
      depositDelay:
        '*{{paymentType}} deposits may take up to 5 minutes to be reflected in your account.',
      error:
        'Unfortunately, there was an issue processing your deposit. Please try again later or contact us.',
      returnToContest: 'Return to Contest',
      continueToLobby: 'Continue to Lobby',
    },
    tooltip: {
      disabledPaymentMethod: '{{paymentMethod}} is currently unavailable',
    },
  },
  deleteAeropayAccount: {
    success: {
      message: 'Aeropay account deleted successfully',
    },
    error: {
      message: 'Failed to delete Aeropay account',
    },
  },

  withdrawal: {
    savedMethods: 'Saved withdrawal methods',
    /** @deprecated - remove when legacy withdrawal form is removed */
    completedLegacy: {
      title: 'Funds withdrawn',
      fundsWithdrawn: 'has been withdrawn from your account',
      newBalance: `Your new balance is {{amount}}`,
      continue: `Continue`,
    },
    completed: {
      title: 'Success!',
      fundsWithdrawn: 'is being transferred to your {{ paymentType }}.',
      newBalance: `Your remaining balance is {{amount}}`,
      continue: `Continue`,
    },
    form: {
      select: {
        title: 'Withdraw Method',
        copy: 'Select your withdrawal method. You can only withdraw funds using methods that you’ve used to deposit funds.',
        labelSaved: 'Saved',
        labelFree: 'Free methods',
        labelPaid: 'Other methods',
      },
      withdraw: {
        title: 'Withdrawal Amount',
        copy: 'Enter your withdrawal amount.',
        labelSaved: 'Saved',
        labelFree: 'Free methods',
        labelPaid: 'Other methods',
      },
      title: 'Withdraw Funds',
      paymentType: {
        title: 'Select your withdrawal method',
        label: 'Payment Type',
        labelFree: 'Free withdrawal options',
        labelPaid: 'Other options',
      },
      amount: {
        title: 'Enter your withdrawal amount',
        label: 'Withdrawal Amount',
        placeholder: '$10.00',
        error: 'Please enter a valid amount',
      },
      card: {
        label: 'Select Card',
      },
      fees: {
        title: 'Withdrawal Fees',
        amount: 'Fee Amount',
      },
      actions: {
        continue: 'Continue',
        withdraw: 'Withdraw {{amount}}',
        tooltip: {
          paymentType: 'Please select a payment type',
          minimum: 'Withdrawal requests must be for at least {{amount}}.',
          insufficientBalance: 'Insufficient balance',
          disabledPaymentType: 'Selected payment type is not supported.',
          emptyCard: 'Card must be selected for withdrawal.',
          emptyCvv: 'CVV must be filled for confirmation.',
        },
        withdrawToCheck: {
          message: 'I would like to withdraw {{amount}} from my balance of {{balance}}',
          mailTitle: 'Withdrawal Request',
          mailMessage: 'Hello, Splash Support - {{message}}',
        },
      },
    },
    summary: {
      withdrawalAmount: 'Withdrawal amount',
      serviceFee: 'Service fee',
      transferAmount: 'Transfer amount',
      remainingBalance: 'Remaining balance',
      remainingBalanceAfterFees: 'Remaining balance after fees',
    },
    fields: {
      amount: {
        label: 'Withdrawal Amount',
        placeholder: '$10.00',
      },
    },
    warnings: {
      cannotWithdrawToCreditCard:
        'For your security, we require a successful debit card deposit before withdrawing to a debit card. Please make a deposit or use one of our other withdrawal methods if you do not have a saved Debit Card.',
      missingKYC: 'We need a little more information from you before you can withdraw.',
    },
    errors: {
      blockedByFeatureFlag: 'Unfortunately, you are not currently allowed to make a withdrawal.',
      blockedByLocation:
        'Unfortunately, you are not currently allowed allowed to make a withdrawal, based on your location.',
      unavailablePaymentType:
        'Unfortunately, withdrawing with {{type}} is not currently available at your location.',
      noAvailableMethods:
        'It looks like there are no available withdrawal methods in your location.',
    },
    descriptions: {
      default: 'Processing fee is {{ fee }}',
      card: 'Withdrawals via debit card will include a {{ fee }} service fee. The service fee will be deducted from the withdrawal amount you enter.',
      paperCheck: 'Withdrawals via check are subject to a {{ fee }} service fee.',
    },
    canada: {
      canadianDollars: 'You will receive Canadian dollars in your account.',
      bankFees: 'Some banks charge processing fees on credit card transactions.',
    },
  },
  errors: {
    insufficientBalance: 'Insufficient balance',
    USER_NOT_AUTHORIZED: {
      title: 'Unauthorized',
      body: 'Please log in.',
    },
    KYC_ALREADY_APPROVED: {
      title: "You've already verified your identity",
      body: 'Go to the contest lobby to check out our contests!',
    },
    KYC_ID_REFERRED: {
      title: 'We need more information',
      body: '',
    },
    KYC_MANUAL_REVIEW: {
      title: 'We need more information to verify your identity',
      body: 'Please contact support if you are having any issues.',
    },
    KYC_REJECTED: {
      title: 'We are unable to verify your identity',
      body: 'Please contact support if you believe there is an issue.',
    },
    ACTIVE_TIMEOUT_EXISTS: {
      title: 'Timeout already applied',
      body: "You've already opted into a timeout.",
    },
    USER_ALREADY_EXISTS: {
      title: 'Duplicate Account',
      body: 'It appears you already have another account. Please contact support for details. ',
    },
    PAYPAL_PAYER_ID: {
      title: 'Invalid request',
      body: "You must first deposit with PayPal before you're able to withdraw to your PayPal account.",
    },
    DUPLICATE_PAYPAL_ACCOUNT: {
      title: 'Invalid PayPal Account',
      body: 'It looks like this PayPal account has already been linked to another Splash User.',
    },
    INSUFFICIENT_BALANCE: {
      title: 'Withdraw-able Balance Exceeded',
      body: "You've attempted to withdraw more than your withdraw-able balance.",
    },
    LIMIT_ALREADY_EXISTS: {
      title: 'Limit already set',
      body: 'You have already this limit set.',
    },
    LIMIT_NOT_FOUND: {
      title: 'Limit not found',
      body: 'The limit does not exist.',
    },
    USER_DEPOSIT_LIMIT_EXCEEDED: {
      title: 'Limit exceeded',
      body: '',
    },
    LOCATION_DEPOSIT_LIMIT_EXCEEDED: {
      title: 'State Deposit Limit',
      body: 'Based on your current location, you are not able to deposit more.',
    },
    MONTHLY_LIMIT_LESS_THAN_WEEKLY: {
      title: 'Invalid Entry',
      body: 'Monthly Limit cannot be less than Weekly limit.',
    },
    WEEKLY_LIMIT_MORE_THAN_MONTHLY: {
      title: 'Invalid Entry',
      body: 'Weekly Limit cannot be greater than Monthly limit.',
    },
    CONTEST_ENTRY_LIMIT: {
      title: 'Limit Reached',
      body: "You've reached your contest entry limit",
    },
    CONTEXT_ENTRY_FEE_LIMIT: {
      title: 'Limit Reached',
      body: "You've reached your contest entry limit",
    },
    USER_NOT_KYC_APPROVED: {
      title: 'Please verify your identity.',
      body: 'Before you are able to deposit or enter a contest, you must finish verifying your identity.',
    },
    INVALID_USER_STATUS: {
      title: 'Please verify your identity.',
      body: 'Before you are able to deposit or enter a contest, you must finish verifying your identity.',
    },
    DEPOSIT_AMOUNT_BELOW_MINIMUM: {
      title: 'Minimum Deposit',
      body: 'Must deposit at least {amount}',
    },
    WITHDRAWAL_AMOUNT_BELOW_MINIMUM: {
      title: 'Minimum Withdrawal',
      body: 'Must withdraw at least {amount}',
    },
    USER_BALANCE_BELOW_MINIMUM_BALANCE: {
      title: 'Minimum Account Balance',
      body: 'Must have an account balance of at least {amount}',
    },
    PAYMENT_METHOD_DISABLED: {
      title: 'Payment Method Disabled',
      body: 'This Payment method is not currently available.',
    },
    USER_NOT_FOUND: {
      title: "We're having an issue",
      body: 'Please refresh and try again.',
    },
    LOCATION_TOKEN_REQUIRED: {
      title: 'Invalid Location',
      body: 'Please make sure you have location services enabled and ad-blockers disabled.',
    },
    LOCATION_TOKEN_INVALID: {
      title: 'Invalid Location',
      body: 'Please contact support.',
    },
    LOCATION_TOKEN_CONFLICT: {
      title: 'Invalid Location',
      body: 'Please contact support.',
    },
    LOCATION_ERROR: {
      title: 'Invalid Location',
      body: 'We are not yet active in your current location.',
    },
    AGE_RESTRICTION: {
      title: 'Below Minimum Age',
      body: 'You do not meet the minimum age requirements for your state.',
    },
    ACTIVE_TIMEOUT: {
      title: 'Timeout on account',
      body: 'There is an active timeout on your account.',
    },
    UNKNOWN: {
      title: "We're having an issue",
      body: 'Please contact support.',
    },
    generic: {
      transactionHistory: {
        title: 'Error',
        message: 'There was an error retrieving your transaction history',
      },
      withdrawal: {
        title: 'Withdrawal Error',
      },
    },
  },
};
