import { ResizingMode } from '@betterpool/api-types/contests-service';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { Group, createStyles } from '@mantine/core';

import { BadgeGuaranteed, BadgeResizable } from '~/components/shared/Badges/Badges';
import { CurrencyConvertor } from '~/components/utils/formatters';
import type { MyUserContest } from '~/domains/contest/domains/myContests/types/userContest';
import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';
import getContestMaxEntriesLabel from '~/domains/contest/domains/gamecenter/domains/common/utils/getContestMaxEntriesLabel';

import type { ContestCardActionBanner } from '../types';

import ContestCardFooterMeta from './ContestCardFooterMeta';

type ContestMetaGenericProps = {
  contest: Contest | MyUserContest;
  actionBanner: ContestCardActionBanner;
  isEntertainmentOnly?: boolean;
};

const useStyles = createStyles({
  prizes: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    justifyContent: 'end',
  },
});

function ContestMetaGeneric({
  actionBanner,
  contest,
  isEntertainmentOnly,
}: ContestMetaGenericProps) {
  const { t } = useTranslation('contest');
  const { classes } = useStyles();

  const maxEntries = getContestMaxEntriesLabel(contest.entries);

  const meta = [
    {
      value: `${contest.entries.filled} / ${maxEntries}`,
      label: (
        <Group spacing={2}>
          {t('common.contestCard.footer.entries')}
          {contest.entries.user ? (
            <span>
              ({contest.entries.user}/{contest.entries.max_per_user})
            </span>
          ) : null}
          {contest.resizing_mode === ResizingMode.RESIZABLE ? (
            <BadgeResizable iconSize={16} />
          ) : null}
        </Group>
      ),
      span: 4,
    },
    !isEntertainmentOnly && {
      value: CurrencyConvertor(contest.entry_fee / 100),
      label: t('common.contestCard.footer.entry'),
      textAlign: 'center',
      span: 4,
    },
    !isEntertainmentOnly && {
      value: CurrencyConvertor(contest.prize_pool / 100),
      label: (
        <div className={classes.prizes}>
          {contest.resizing_mode === ResizingMode.GUARANTEED ? (
            <BadgeGuaranteed iconSize={16} />
          ) : null}
          {t('common.contestCard.footer.prizes')}
        </div>
      ),
      textAlign: 'right',
      span: 4,
    },
  ].filter(Boolean);

  return <ContestCardFooterMeta actionBanner={actionBanner} meta={meta} />;
}

export default ContestMetaGeneric;
