import React, { useMemo, useRef, useState } from 'react';
import type { NumberInputHandlers } from '@mantine/core';
import {
  Group,
  LoadingOverlay,
  NumberInput,
  Skeleton,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import { IconPlus, IconMinus } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';

import { CurrencyConvertor } from '~/components/utils/formatters';
import { Text } from '~/domains/design-system/Text';
import { Title } from '~/domains/design-system/Title';
import { Button } from '~/domains/design-system/Button';
import type { FullContest } from '~/domains/contest/domains/services/interfaces/full-contest.interface';
import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';

import ContestCard from '../../ContestCard/ContestCard';
import dt from '../../../testing.const';

import useStyles from './EnterContestScreen.styles';

function EnterContestScreen({
  balanceInDollars,
  contest,
  onDepositClick,
  onCreateEntries,
  onClose,
  errorMessage,
  isCreateEntriesReady,
}: {
  balanceInDollars: number;
  contest: FullContest | Contest;
  onDepositClick: () => void;
  onCreateEntries: (entriesCount: number) => void;
  onClose: () => void;
  errorMessage?: string;
  isCreateEntriesReady: boolean;
}) {
  const { t } = useTranslation('contest');
  const theme = useMantineTheme();
  const handlers = useRef<NumberInputHandlers>();

  const [value, setValue] = useState(1);
  const maxEntries = contest.entries.max === 0 && contest.entries.is_unlimited_entries
    ? Infinity
    : contest.entries.max;
  const maxAllowedEntries = useMemo(
    () =>
      contest?.entries
        ? Math.min(
            contest.entries.max_per_user - contest.entries.user ?? 0,
            maxEntries - contest.entries.filled
          )
        : 0,
    [contest, maxEntries]
  );

  const isOverBalance = useMemo(() => {
    if (typeof balanceInDollars !== 'number') {
      return false;
    }

    return ((contest?.entry_fee ?? 0) / 100) * value > balanceInDollars;
  }, [balanceInDollars, contest?.entry_fee, value]);

  const isEntertainmentOnly = contest.settings?.entertainmentOnlySettings?.enabled;

  const { classes } = useStyles();

  return (
    <div>
      <Stack
        align="center"
        ta="center"
        data-test-id={dt.enterContestModal.modal}
        spacing={theme.other.spacing._8}
      >
        <Title variant="headline-xl" color="gray2.7">
          {t('common.enterContestModal.enterContest.title')}
        </Title>
        {(() => {
          if (errorMessage) {
            return (
              <Text variant="body-large" color={theme.colors.orange[3]}>
                {errorMessage}
              </Text>
            );
          }

          if (contest.entries.max_per_user > 1) {
            return (
              <Text variant="body-large" color={theme.colors.gray2[4]}>
                {t('common.enterContestModal.enterContest.text')}
              </Text>
            );
          }

          return null;
        })()}

        {typeof balanceInDollars === 'number' && !isEntertainmentOnly && (
          <Text variant="title-medium" color={theme.colors.gray2[3]}>
            {t('common.enterContestModal.enterContest.balance', {
              balance: CurrencyConvertor(balanceInDollars),
            })}
          </Text>
        )}
      </Stack>

      {contest && (
        <div className={classes.contestCardSection}>
          <div className={classes.contestCardContainer}>
            <ContestCard
              contest={contest}
              isContestThumbnailShown
              isStatic
              isCardMenuVisible={false}
            />
          </div>
        </div>
      )}


      {contest.entries.max_per_user > 1 && (
        <Stack spacing={4} w="100%" align="center">
          <Group spacing={theme.other.spacing._16}>
            <Button
              size="medium-icon"
              variant="tertiary-fill"
              onClick={() => {
                handlers.current.decrement();
              }}
              data-test-id={dt.enterContestModal.action.decrement}
              disabled={maxAllowedEntries === 1 || value === 1}
            >
              <IconMinus size={20} color="currentColor" />
            </Button>
            <NumberInput
              hideControls
              value={value}
              onChange={setValue}
              data-test-id={dt.enterContestModal.action.numberOfEntries}
              handlersRef={handlers}
              min={1}
              max={maxAllowedEntries}
              classNames={{ input: classes.numberInput }}
              disabled={maxAllowedEntries === 1}
            />
            <Button
              size="medium-icon"
              variant="tertiary-fill"
              onClick={() => {
                handlers.current.increment();
              }}
              data-test-id={dt.enterContestModal.action.increment}
              disabled={maxAllowedEntries === 1 || value === maxAllowedEntries}
            >
              <IconPlus size={20} color="currentColor" />
            </Button>
          </Group>
          <Text variant="body-medium" color={theme.colors.gray2[3]}>
            {t('common.enterContestModal.enterContest.maxEntries__hasPlural', {
              count: maxAllowedEntries,
            })}
          </Text>
        </Stack>
      )}

      <div className={classes.footer}>
        {!errorMessage ? (
          <Skeleton visible={!isCreateEntriesReady} className={classes.ctaWrapper}>
            <LoadingOverlay visible={!isCreateEntriesReady} />
            {isOverBalance ? (
              <Button
                variant="primary-fill"
                className={classes.cta}
                onClick={onDepositClick}
                data-test-id={dt.enterContestModal.action.depositToEnter}
              >
                {t('common.enterContestModal.enterContest.depositToEnter')}
              </Button>
            ) : (
              <Button
                variant="primary-fill"
                className={classes.cta}
                onClick={() => {
                  onCreateEntries(value);
                }}
                data-test-id={dt.enterContestModal.action.createEntries}
                disabled={!value || !isCreateEntriesReady}
              >
                {isEntertainmentOnly ? (
                  t('common.enterContestModal.enterContest.addEntriesEntertainmentOnly__hasPlural', {
                    count: value ?? 1,
                  })
                ) : t('common.enterContestModal.enterContest.addEntries__hasPlural', {
                  count: value ?? 1,
                  entryFee: CurrencyConvertor(((contest?.entry_fee ?? 0) / 100) * (value ?? 1)),
                })}
              </Button>
            )}
          </Skeleton>
        ) : (
          <Button
            variant="secondary-fill"
            className={classes.cta}
            onClick={onClose}
            data-test-id={dt.enterContestModal.action.cancel}
          >
            {t('common.enterContestModal.enterContest.close')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default EnterContestScreen;
