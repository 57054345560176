import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  contestCardSection: {
    padding: `${theme.other.spacing._24}px 0`,
    display: 'flex',
    justifyContent: 'center',
  },
  contestCardContainer: {
    maxWidth: 400,
    width: '100%',
  },
  numberInputContainer: {
    [theme.fn.smallerThan('md')]: {
      width: '100%',
      flexGrow: 1,
    },
  },
  numberInput: {
    width: 80,
    height: 40,
    textAlign: 'center',
    borderRadius: theme.other.spacing._8,
    borderColor: theme.colors.gray2[1],
  },
  footer: {
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'start',
    width: '100%',
    marginTop: theme.other.spacing._24,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing.md,
      textAlign: 'center',
    },
  },
  ctaWrapper: {
    width: '100%',
  },
  cta: {
    width: '100%',
  }
}));

export default useStyles;
