import { useContext, useEffect } from 'react';
import type { EntriesDetails, ContestStatus } from '@betterpool/api-types/contests-service';

import { UserContext } from '~/components/providers/UserProvider';

import useEnterContestValidation from '../../hooks/useEnterContestValidation';
import useCreateOrJoinEntry from '../../hooks/useCreateOrJoinEntry';

type UseContestEntryButtonProps = {
  isAfterDeadline: boolean;
  isEntertainmentOnly: boolean;
  entries?: EntriesDetails;
  isAllowedInLocation?: boolean;
  status: ContestStatus;
  onClose: () => void;
};

const useCreateEntries = ({
  isAfterDeadline,
  isEntertainmentOnly,
  entries,
  isAllowedInLocation,
  status,
  onClose,
}: UseContestEntryButtonProps) => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      onClose();
    }
  }, [onClose, user]);

  const { errorMessage, isLoading } = useEnterContestValidation({
    isAfterDeadline,
    isEntertainmentOnly,
    entries,
    isAllowedInLocation,
    status,
  });
  const {
    isLoading: isCreateEntriesLoading,
    handleCreateOrJoinContest,
    isSuccess: isCreateEntriesSuccess,
  } = useCreateOrJoinEntry();

  return {
    createEntriesErrorMessage: errorMessage,
    isCreateEntriesLoading,
    createEntries: handleCreateOrJoinContest,
    isCreateEntriesReady: !isLoading,
    isCreateEntriesSuccess,
  };
};

export default useCreateEntries;
