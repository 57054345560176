import { createStyles } from '@mantine/core';
import type { ComponentProps } from 'react';

import dt from '~/testing';
import { Text, textStyles } from '~/domains/design-system/Text';

const useStyles = createStyles((theme) => ({
  container: {
    flex: '1 1 0%',
  },
  input: {
    display: 'none',
    '&:checked ~ label': {
      background: theme.colors.gray2[5],
      borderColor: theme.colors.gray2[5],
      color: theme.white,
    },
  },
  label: {
    width: '100%',
    height: '48px',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.colors.gray2[1]}`,
    ...textStyles['subhead-small'],
    cursor: 'pointer',
    transition: 'border-color 150ms ease-in-out, color 150ms ease-in-out, background-color 150ms ease-in-out',
  }
}));

type DepositOptionRadioProps = {
  isActive: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
  value: string;
};

export function DepositOptionRadio({
  isActive,
  onChange,
  children,
  value,
}: DepositOptionRadioProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <input
        type="radio"
        className={classes.input}
        checked={isActive}
        onChange={onChange}
        value={value}
        id={`deposit-option-${value}`}
        name="depositOption"
        aria-label={children.toString()}
      />
      <label
        htmlFor={`deposit-option-${value}`}
        className={classes.label}
        data-test-id={dt.payments.deposits.depositForm.depositOption}
      >
        {children}
      </label>
    </div>
  )
}
