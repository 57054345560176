import { useEffect , useState } from "react";

import { TIMEOUT as DIALOG_TIMEOUT } from '~/domains/design-system/components/Dialog/constants';

function useAnimatedDialogModals<MT>({
  modals,
  timeout = DIALOG_TIMEOUT,
}: {
  modals: MT[];
  timeout?: number;
}) {
  const [modalsCache, setModalsCache] = useState<MT[]>([]);
  const [activeModal, setActiveModal] = useState<MT | null>(null);
  const [closingModal, setClosingModal] = useState<MT | null>(null);

  useEffect(() => {
    const lastModal = modals[modals.length - 1];

    if (!lastModal) {
      setActiveModal(null);
      setClosingModal(activeModal);

      const timer = setTimeout(() => {
        setModalsCache(modals);
        setClosingModal(null);
      }, timeout);

      return () => clearTimeout(timer);
    }

    if (activeModal === lastModal) { return; }

    setClosingModal(activeModal);
    setActiveModal(null);

    const timer = setTimeout(() => {
      setClosingModal(null);
      setActiveModal(lastModal);
      setModalsCache(modals);
    }, timeout);

    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modals]);

  return {
    modalsCache,
    activeModal,
    closingModal,
  };
}

export default useAnimatedDialogModals;
