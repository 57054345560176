import React, { useState, useCallback, useRef, useMemo } from 'react';

type ContextValue<MT> = {
  isCloseDisabled: boolean;
  modals: MT[];
  closeModal: (args?: unknown) => void;
  disableClose: () => void;
  enableClose: () => void;
  openModal: (modal: MT) => Promise<unknown>;
};

type ProviderProps = {
  children: React.ReactNode;
};

function createModalsContext<MT>() {
  const ModalsContext = React.createContext<ContextValue<MT>>({
    isCloseDisabled: false,
    modals: [],
    closeModal: () => {},
    disableClose: () => {},
    enableClose: () => {},
    openModal: () => Promise.resolve(),
  });

  function ModalsContextProvider({ children }: ProviderProps) {
    const [modals, setModals] = useState<MT[]>([]);
    const [isCloseDisabled, setIsCloseDisabled] = useState(false);
    const resolvers = useRef<((value: unknown) => void)[]>([]);

    const handleCloseModal = useCallback(
      (args: unknown) => {
        setModals((v) => {
          const newModals = v.slice(0, -1);

          if (newModals.length === 0) {
            setIsCloseDisabled(false);
          }

          return newModals;
        });
        try {
          const resolve = resolvers.current.pop();
          resolve(args);
          // eslint-disable-next-line no-empty
        } catch (e) {
          console.error('Error while closing modal', e);
        }
      },
      [setModals]
    );

    const handleOpenModal = useCallback(
      (modal: MT) =>
        new Promise((resolve) => {
          setModals((v) => [...v, modal]);
          resolvers.current.push(resolve);
        }),
      []
    );

    const handleDisableClose = useCallback(() => {
      setIsCloseDisabled(true);
    }, [setIsCloseDisabled]);

    const handleEnableClose = useCallback(() => {
      setIsCloseDisabled(false);
    }, [setIsCloseDisabled]);

    const value = useMemo(
      () => ({
        isCloseDisabled,
        modals,
        closeModal: handleCloseModal,
        disableClose: handleDisableClose,
        enableClose: handleEnableClose,
        openModal: handleOpenModal,
      }),
      [
        isCloseDisabled,
        modals,
        handleCloseModal,
        handleDisableClose,
        handleEnableClose,
        handleOpenModal,
      ]
    );

    return <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>;
  }

  return {
    ModalsContext,
    ModalsContextProvider,
  };
}

export default createModalsContext;
