import { createStyles } from '@mantine/core';
import { useContext, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import { ButtonLink } from '~/domains/design-system/Button';
import { Routes } from '~/domains/common/constants/routes';
import { titleStyles } from '~/domains/design-system/Title';
import { FEATURE } from '~/common/enums/feature.enum';
import { useTreatments } from '~/domains/common/hooks/useTreatments';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import { TUTORIAL_PLACEMENTS } from '~/domains/cms/tutorials/const';

import SitesMenu from '../common/SitesMenu/SitesMenu';
import Logo from '../common/Logo';
import { HeaderMenusContext } from '../HeaderMenusContext';
import { AppShellHeaderSearchButton } from '../AppShellHeaderSearchButton';
import { HEADER_HEIGHT } from '../constants';
import NotificationBadge from '../NotificationBadge';

import MobileLogoutMenu from './MobileLogoutMenu';
import dt from '~/testing';

const useStyles = createStyles((theme) => ({
  rightSlot: {
    marginLeft: 'auto',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  nav: {
    display: 'flex',
    marginLeft: -theme.spacing.lg,

    [theme.fn.smallerThan('lg')]: {
      marginLeft: -theme.spacing.sm,
    },

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    ...titleStyles['subhead-medium'],
    borderTop: '2px solid transparent',
    borderBottom: '2px solid transparent',
    padding: `${theme.spacing.xs}px ${theme.spacing.lg}px`,
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',
      '&:hover': {
        opacity: 0.8,
      },
    },

    [theme.fn.smallerThan('lg')]: {
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    },

    '&.isActive': {
      color: theme.colors.blue[4],
      borderBottomColor: theme.colors.blue[4],
    },
  },
  navLinkLabel: {
    position: 'relative',
  },
  navLinkBadge: {
    display: 'flex',
    position: 'absolute',
    top: -4,
    left: '100%',
  },
}));

function AppShellHeaderSignedOut() {
  const router = useRouter();
  const { t } = useTranslation('common');
  const { classes, cx } = useStyles();
  const { onMenuOpen } = useContext(HeaderMenusContext);
  const propsEnabled = useTreatments([FEATURE.ENABLE_PROPS]);
  const isPropsEnabled =
    propsEnabled.isReady && propsEnabled?.treatments[FEATURE.ENABLE_PROPS].treatment === 'on';
  const isMobile = useIsMobile();

  const navLinks = useMemo(
    () => [
      { href: Routes.main(), label: t('appShellHeader.home'), isExact: true },
      { href: Routes.contestLobby(), label: t('appShellHeader.contestLobby') },
      ...(isPropsEnabled
        ? [
            {
              href: Routes.quickPicksBoard(),
              label: (
                <>
                  {t('appShellHeader.quickPicks')}
                  {!Cookies.get(TUTORIAL_PLACEMENTS.TUTORIAL_QUICK_PICKS_BOARD) && (
                    <div className={classes.navLinkBadge}>
                      <NotificationBadge showEmpty />
                    </div>
                  )}
                </>
              ),
            },
          ]
        : []),
    ],
    [t, isPropsEnabled, classes.navLinkBadge]
  );

  if (isMobile) {
    return (
      <>
        <SitesMenu />
        <Logo />
        <div className={classes.rightSlot}>
          <AppShellHeaderSearchButton />
          <ButtonLink
            variant="primary-fill"
            size="small"
            href={Routes.signIn({
              brand: router.query.brand as string,
              redirectTo: window.location.pathname + window.location.search,
            })}
            onClick={() => {
              onMenuOpen();
            }}
          >
            {t('appShellHeader.logIn')}
          </ButtonLink>
          <MobileLogoutMenu />
        </div>
      </>
    );
  }

  return (
    <>
      <SitesMenu />
      <Logo />
      <nav className={classes.nav}>
        {navLinks.map(({ href, label, isExact }) => (
          <Link
            data-test-id={dt.components.shared.header.signedOutHeaderButton}
            href={href}
            className={cx(classes.navLink, {
              isActive: isExact ? router.asPath === href : router.asPath.startsWith(href),
            })}
            key={href}
          >
            <div className={classes.navLinkLabel}>{label}</div>
          </Link>
        ))}
      </nav>
      <div className={classes.rightSlot}>
        <AppShellHeaderSearchButton />
        <ButtonLink
          variant="primary-fill"
          size="small"
          href={Routes.signIn({
            brand: router.query.brand as string,
            redirectTo: window.location.pathname + window.location.search,
          })}
          onClick={() => {
            onMenuOpen();
          }}
        >
          {t('appShellHeader.logIn')}
        </ButtonLink>
      </div>
    </>
  );
}

export default AppShellHeaderSignedOut;
