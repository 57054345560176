import type { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';

export const setAuthEndpoint = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async (config) => {
      // only allow universal auth endpoints (before launch) on non-production environments
      const isUniversalAuthEnabled =
        process.env.NEXT_PUBLIC_ENV !== 'production' &&
        Cookies.get('ENABLE_UNIFIED_LOGIN') === 'on';

      if (config.url) {
        config.url = config.url.replace(
          /^\/(auth|universal-auth)\//,
          isUniversalAuthEnabled ? '/universal-auth/' : '/auth/'
        );
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};
