export default {
  action: {
    cta: 'dt.contest.create.action.cta',
    next: 'dt.contest.create.action.next',
    back: 'dt.contest.create.action.back',
    submit: 'dt.contest.create.action.submit',
    login: 'dt.contest.create.action.login',
    invite: 'dt.contest.create.action.invite',
    view: 'dt.contest.create.action.view',
    editSettings: 'dt.contest.create.action.edit.settings',
    showAll: 'dt.contest.create.action.view.all',
    showLess: 'dt.contest.create.action.view.less',
  },
  container: 'dt.contest.create.container',
  field: (sectionKey: string, path: string[]) =>
    `dt.contest.create.field.${sectionKey}.${path.join('.')}`,
  section: (sectionKey: string, name: 'label' | 'description') =>
    `dt.contest.create.section.${sectionKey}.${name}`,
  switchOption: 'dt.contest.create.switch.option',
  selectOption: 'dt.contest.create.field.select.option',
  summaryItem: 'dt.contest.create.summaryItem',
  checkOption: 'dt.contest.create.field.check.option',
  fieldMeta: {
    title: 'dt.contest.create.field.meta.title',
    description: 'dt.contest.create.field.meta.description',
    error: 'dt.contest.create.field.meta.error',
  },
  image: {
    container: 'dt.contest.create.image.container',
    success: 'dt.contest.create.image.success',
    reject: 'dt.contest.create.image.error',
    idle: 'dt.contest.create.image.idle',
    drag: 'dt.contest.create.image.drag',
  },
  numberField: 'dt.contest.create.number.field',
  summary: {
    label: (label: string) => `dt.contest.create.summary.label.${label}`,
    subLabel: (label: string) => `dt.contest.create.summary.sublabel.${label}`,
    field: (field: string) => `dt.contest.create.summary.field.${field}`,
    action: {
      change: 'dt.contest.create.summary.action.change',
    },
  },
  table: {
    container: 'dt.contest.create.table.container',
    head: {
      label: 'dt.contest.create.table.header.label',
    },
    body: {
      row: 'dt.contest.create.table.body.row',
      cell: 'dt.contest.create.table.body.cell',
      switch: 'dt.contest.create.table.body.cell.switch',
      select: 'dt.contest.create.table.body.cell.select',
    },
  },
  segments: {
    container: 'dt.contest.create.segments.container',
    action: {
      save: 'dt.contest.create.segments.action.save',
      edit: 'dt.contest.create.segments.action.edit',
    },
    edit: {
      slate: 'dt.contest.create.segments.edit.slate',
      segment: 'dt.contest.create.segments.edit.segment',
    },
    view: {
      segment: 'dt.contest.create.segments.view.segment',
    },
  },
  screen: {
    inprogress: 'dt.contest.create.screen.inprogress',
    submitted: 'dt.contest.create.screen.submitted',
  },
  drawer: {
    container: 'dt.contest.create.drawer.container',
    close: 'dt.contest.create.drawer.close',
  },
  previous: {
    container: 'dt.contest.create.previous.container',
    contest: 'dt.contest.create.previous.contest',
  },
};
