import { createStyles, Modal, useMantineTheme } from '@mantine/core';

import { DepositView } from './DepositView';
import Dialog from '~/domains/design-system/components/Dialog/Dialog';
import DialogBody from '~/domains/design-system/components/Dialog/DialogBody/DialogBody';

const useStyles = createStyles((theme) => ({
  container: {
    width: 780,

    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },
}));

type DepositModalProps = {
  isOpen?: boolean;
  onClose: VoidFunction;
  contestIdForPostDeposit?: string;
  fallbackUrl?: string;
};

function DepositModal({
  isOpen = true,
  onClose,
  contestIdForPostDeposit,
  fallbackUrl,
}: DepositModalProps) {
  const { classes } = useStyles();

  return (
    <Dialog
      onClose={onClose}
      isOpen={isOpen}
      type={{
        desktop: 'modal',
        mobile: 'modal-full-screen',
      }}
      className={classes.container}
    >
      <DialogBody>
        <DepositView
          contestIdForPostDeposit={contestIdForPostDeposit}
          onContinue={onClose}
          onLeave={onClose}
          fallbackUrl={fallbackUrl}
        />
      </DialogBody>
    </Dialog>
  );
}

export default DepositModal;
