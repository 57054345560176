import { ActionIcon, createStyles, Group, Stack, Tooltip } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import type { ComponentProps } from 'react';

import { PaymentType } from '~/common/enums/payments.enum';
import { snakeCaseToTitleCase, upperCaseToTitleCase } from '~/components/utils/formatters';
import { Text } from '~/domains/design-system/Text';
import { useIsCanada } from '~/domains/common/hooks/useIsCanada';
import dt from '~/testing';
import type { BankAccount } from '~/services/wallet/interfaces/wallets.interface';
import IconTrash from '~/domains/design-system/icons/IconTrash';
import { BankIcon } from '~/domains/payments/icons/BankIcon';

import PaymentTypeIcon from '../../icons/PaymentTypeIcon';
import type { PaymentTypeSettings } from '../hooks/useWithdrawalPaymentMethods';
import { useDeleteAeropayAccount } from '../../hooks/useDeleteAeropay';

const useStyles = createStyles((theme, { isBankAccount }: { isBankAccount?: boolean }) => ({
  container: {
    '&.isFullWidthRadio': {
      gridColumn: '1 / span 2',
    },
  },
  label: {
    width: '100%',
    border: `1px solid ${theme.colors.gray2[1]}`,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: isBankAccount ? 'auto' : 96,
    transition: 'border-color 150ms ease-in-out',

    '&[data-disabled]': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  },
  input: {
    display: 'none',

    '&:checked ~ label': {
      background: theme.white,
      borderColor: theme.colors.gray[7],
    },
  },
}));

type PaymentTypeRadioProps = {
  type: PaymentType;
  value: string;
  settings: PaymentTypeSettings;
  isActive: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  superscriptIndex: number;
  // in withdrawal form, we have a full width radio for recommended VIPPreferred and Skrill
  isWithdrawal?: boolean;
  bankAccount?: BankAccount;
  isDeleteBankAccountVisible?: boolean;
};

export function PaymentTypeRadio({
  type,
  value,
  settings,
  isActive,
  onChange,
  superscriptIndex,
  isWithdrawal = true,
  bankAccount,
  isDeleteBankAccountVisible = false,
}: PaymentTypeRadioProps) {
  const { t } = useTranslation('payments');
  const isCanada = useIsCanada();
  const { classes, cx, theme } = useStyles({ isBankAccount: !!bankAccount });

  const { mutate: deleteAeropayAccount, isLoading: isDeletingAeropayAccount } =
    useDeleteAeropayAccount();

  const isFullWidthRadio =
    isWithdrawal &&
    (type === PaymentType.VIPPREFERRED || (type === PaymentType.SKRILL && isCanada));

  const CustomContent = (
    <div className={cx(classes.container, { isFullWidthRadio})}>
      <input
        type="radio"
        name="paymentType"
        id={`${type}-${value}`}
        value={value}
        checked={isActive}
        onChange={onChange}
        disabled={settings.isDisabled}
        aria-label={t(`paymentTypes.${type}`)}
        className={classes.input}
      />
      <label
        className={classes.label}
        htmlFor={`${type}-${value}`}
        data-disabled={settings.isDisabled ? true : undefined}
      >
          {bankAccount ? (
            <Group
              data-test-id={dt.payments.withdrawal.components.bankAccount}
              w="100%"
              py={12}
              px={16}
              sx={{
                img: {
                  width: 32,
                  height: 32,
                  borderRadius: 4,
                },
              }}
            >
              <BankIcon bankName={bankAccount.bankName} />
              <Stack align="flex-start" spacing={0} sx={{ flex: 1 }}>
                <Text variant="subhead-small" color="gray.9">
                  {bankAccount.name}
                </Text>
                <Text variant="body-small" color="gray.6">
                  {bankAccount.bankName}
                </Text>
              </Stack>
              {isDeleteBankAccountVisible && (
                <ActionIcon
                  data-test-id={dt.payments.withdrawal.components.deleteBankAccountButton}
                  loading={isDeletingAeropayAccount}
                  onClick={() => {
                    deleteAeropayAccount({ bankAccountId: bankAccount.bankAccountId });
                  }}
                >
                  <IconTrash size={20} color={theme.colors.gray[6]} />
                </ActionIcon>
              )}
            </Group>
          ) : (
            <Stack data-test-id={dt.payments.withdrawal.components.paymentType} spacing={8}>
              <Stack
                w={type === PaymentType.AEROPAY ? 76 : 36}
                h={24}
                align="center"
                justify="center"
                sx={
                  type === PaymentType.AEROPAY || type === PaymentType.CARD
                    ? { alignSelf: 'center' }
                    : {
                        alignSelf: 'center',
                        borderRadius: 4,
                        border: `1px solid ${theme.colors.gray[2]}`,
                      }
                }
              >
                <PaymentTypeIcon
                  type={type}
                  color={settings.isDisabled ? theme.colors.gray[6] : undefined}
                  size={type === PaymentType.AEROPAY ? 10 : 20}
                />
              </Stack>
              <Text variant="subhead-small" color={settings.isDisabled ? 'gray.6' : undefined}>
                {
                  // It's possible to withdraw only to a debit cards. Credit cards are filtered out in usePaymentMethods, so we can safely assume that the card is a debit card.
                  type === PaymentType.CARD ? t('paymentTypes.DEBIT_CARD') : t(`paymentTypes.${type}`)
                }
                {!settings.isFree ? (
                  <Text variant="body-small" component="sup" c="gray.6" sx={{ marginLeft: 2 }}>
                    {superscriptIndex}
                  </Text>
                ) : null}
              </Text>
            </Stack>
          )}
      </label>
    </div>
  )

  return settings.isDisabled ? (
    <Tooltip
      key={type}
      label={
        settings.disabledReason ??
        t('deposits.tooltip.disabledPaymentMethod', {
          paymentMethod: upperCaseToTitleCase(snakeCaseToTitleCase(type)),
        })
      }
      multiline
      events={{ hover: true, focus: true, touch: true }}
      zIndex={99}
    >
      <div
        //  Div wrapper is required for proper event propagation. Without it, hover/focus events might not bubble up correctly to the Tooltip.
        style={{ width: '100%' }}
      >
        {CustomContent}
      </div>
    </Tooltip>
  ) : (
    CustomContent
  );
}
