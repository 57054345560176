import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  restartedContestWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.other.spacing._12,
    gap: theme.other.spacing._8,
  },
  restartedContestText: {
    maxWidth: 430,
    textAlign: 'center',
  },
  loaderContainer: {
    height: '100%',
    minHeight: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  body: {
    minWidth: '500px',

    [theme.fn.smallerThan('sm')]: {
      minWidth: '100%',
    },
  },

  content: {
    transition: 'opacity 300ms ease-in-out',
  },

  contentEnter: {
    opacity: 0,
  },
  contentEnterActive: {
    opacity: 1,
    transition: 'opacity 300ms ease-in-out',
  },
  contentExitActive: {
    opacity: 0,
    transition: 'opacity 300ms ease-in-out',
  },
  contentExit: {
    opacity: 1,
  },
}));

export default useStyles;
